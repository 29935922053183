/**
 * @ComponentFor SizeGuidePageViewModel
 */
import React from 'react';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import SizeGuidePageViewModelType from './SizeGuidePageViewModel.type';

type PropType = SizeGuidePageViewModelType & {};

type ConnectedProps = {
  currentBreakpoint: number;
};

type Props = PropType & ConnectedProps;

export default function SizeGuidePage(props: Props) {
  const dataDisplay = props.imageUrl ? (
        <img src={props.imageUrl}/>
      ) : (
      <table>
        <thead>
          <tr>
            {props.sizeHeader.map((item, index) => (
              <th key={item}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.sizes.map((line, index) => (
            <tr key={index}>
              {line.map((size, i) => (
                <td key={size}>{size}</td>
              ))}
            </tr>
          ))}
        </tbody>
        </table>
      );

  return (
    <Main appearance={PageAppearance.Narrow}>
      <Section>
        <h1>{props.title}</h1>
        <h3>{props.description}</h3>
        {dataDisplay}
      </Section>
    </Main>
  );
}
